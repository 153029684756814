import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import React, { useEffect, useState } from 'react';
import useResetState from '../../../model/redux/utils/useResetState';
import { useRouter } from 'next/router';
import { useGlobalMessage } from '../global_message';


const DialogFooter = ({ onOk }) => {
    return (
        <>
            <Button label="Continuar" className="p-button-text" onClick={onOk} />
        </>
    )
}

const SessionExpiredModal = ({ visible = false, onClose = ()=>{} }) => {

    if (!visible) {
        return null;
    }

    const { resetState } = useResetState();
    const { clearMessages } = useGlobalMessage();
    const router = useRouter();

    const handleLogout = () => {
        try {
            clearMessages();
            onClose();
            resetState();
            router.push('/auth/login');
        } catch (error) {
            console.error(error)
        }
    };

    return (
        <Dialog visible={visible} style={{ width: '450px' }} header="Sesión Expirada" modal footer={<DialogFooter onOk={handleLogout} />} onHide={handleLogout}>
            <div className="flex align-items-center justify-content-center">
                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                <span>
                    Su sesión ha expirado, por favor inicie sesión nuevamente para continuar.
                </span>
            </div>
        </Dialog>
    )


}

export default SessionExpiredModal;