import React, { ReactElement, ReactNode } from 'react';



type IfElseProps = {
    render:boolean,
    children?:ReactNode[]|ReactElement[]
}

type RenderIfElseProps = {
    render:boolean,
    renderIf:ReactNode,
    renderElse:ReactNode
}

type RenderCaseItem = {
    value:boolean,
    item:ReactNode
}

type RenderCaseProps = {
    value:boolean,
    items:RenderCaseItem[]
}

type RenderIFProps = {
    render:boolean,
    children?:React.ReactNode
}

export function RenderIF({render=true, children}:RenderIFProps){
    if(!render) return null;
    return <>{children}</>;
}

export function IfElse({render=true, children}:IfElseProps):ReactNode{
    if(render) return <>{children[0]}</>;
    return <>{children[1]}</>
}

export function RenderIfElse({render=true, renderIf, renderElse}:RenderIfElseProps):ReactNode{
    return render? renderIf: renderElse;
}

export function RenderCase({value, items}:RenderCaseProps):ReactNode{
    const item = items?.find(item=>item.value===value);
    return item.item;
}

export function Line({mt, mb, mx, my}){
    return (
        <div style={{width:'100%', height:'1px', backgroundColor:'#e5e7eb', marginTop:mt||my, marginBottom:mb||my}}/>
    )
}