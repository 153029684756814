import React, { useEffect, useState } from 'react';
import { LayoutProvider } from '../layout/context/layoutcontext';
import Layout from '../layout/layout';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import '../styles/layout/layout.scss';
import '../styles/demo/Demos.scss';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from '../model/redux/store';
import { UsuarioSelectors } from '../model/redux/reducers/UsuarioReducer';
import { useStore } from "react-redux";
import useResetState from '../model/redux/utils/useResetState';
import { ErrorListener } from '../model/api/client/responseHandler';
import SessionExpiredModal from '../components/ui/auth/SessionExpiredModal';
import { ConfirmDialog } from '../components/ui/dialog';
import { useRouter } from 'next/router';
import { GlobalMessageProvider } from '../components/ui/global_message';
import { RenderIF } from '../components/ui/view';

function SessionExpirate({ }) {
    const { getState } = useStore(store);
    const { resetState } = useResetState();

    const { expireTime } = UsuarioSelectors.data(getState());
    
    const [cont, setCont] = useState(0)

    if (cont == 0){
        setCont(cont + 1)
        
        if((new Date()) > new Date(expireTime)){
            resetState()
        }
        
    }

    return null;

}

export function RenderView({Component, pageProps}){

    if(Component?.getLayout){
        return Component.getLayout(<Component {...pageProps} />);
    }

    return (
        <Layout>
            <Component {...pageProps} />
        </Layout>
    );
    
}

export function SessionHandler({component}){

    const [showExpiredModal, setShowExpiredModal] = useState(false);

    useEffect(()=>{
        const onErrorListener = (error)=>{
            setShowExpiredModal(true);
        }
        ErrorListener.addListener(onErrorListener);
    },[])

    if(component.getLayout){
        return null;
    }

    return (
        <>
            <SessionExpirate />
            <SessionExpiredModal visible={showExpiredModal} onClose={()=>setShowExpiredModal(false)} />
        </>
    );

}

export default function MyApp({ Component, pageProps }) {

    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>

                <GlobalMessageProvider>
                    <SessionHandler component={Component} />
                    <LayoutProvider>
                        <RenderView Component={Component} pageProps={pageProps}/>
                    </LayoutProvider>
                </GlobalMessageProvider>
                
            </PersistGate>
        </Provider>
    );

    // return (

    //     <Provider store={store}>
    //         <PersistGate loading={null} persistor={persistor}>
    //             <RenderIF render={!isLogin}>
    //                 <>
    //                     <SessionExpirate />
    //                     <SessionExpiredModal visible={showExpiredModal} onClose={()=>setShowExpiredModal(false)} />
    //                 </>
    //             </RenderIF>
    //         <GlobalMessageProvider>
    //             <LayoutProvider>
    //                 {isLogin? (Component.getLayout(<Component {...pageProps} />)) : 
    //                     (
    //                         <Layout>
    //                             <Component {...pageProps} />
    //                         </Layout>
    //                     )
    //                 }
    //             </LayoutProvider>
    //         </GlobalMessageProvider>                            
    //         </PersistGate>
    //     </Provider>
    // );

    // if (Component.getLayout) {
    //     return (
    //         <Provider store={store}>
    //             <PersistGate loading={null} persistor={persistor}>
    //                 <LayoutProvider>
    //                     {Component.getLayout(<Component {...pageProps} />)}
    //                 </LayoutProvider>
    //             </PersistGate>
    //         </Provider>

    //     )
    // } else {
    //     return (
    //         <Provider store={store}>
    //             <PersistGate loading={null} persistor={persistor}>
    //                 <SessionExpirate />
    //                 <SessionExpiredModal visible={showExpiredModal} onClose={()=>setShowExpiredModal(false)} />
    //                 <GlobalMessageProvider>
    //                 <LayoutProvider>
    //                     <Layout>
    //                         <Component {...pageProps} />
    //                     </Layout>
    //                 </LayoutProvider>
    //                 </GlobalMessageProvider>
    //             </PersistGate>
    //         </Provider>

    //     );
    // }
}
