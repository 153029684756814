import React, { createContext, useContext, useRef } from 'react';
import { Toast } from 'primereact/toast';

const GlobalMessageContext = createContext();

export const useGlobalMessage = () => useContext(GlobalMessageContext);

export const GlobalMessageProvider = ({ children }) => {
    
    const toast = useRef(null);

    const showMessage = (message) => {
        toast.current.show(message);
    };

    const clearMessages = () =>{
        toast.current.clear();
    }

    return (
        <GlobalMessageContext.Provider value={{ showMessage, clearMessages }}>
            {children}
            <Toast ref={toast} position="top-right" />
        </GlobalMessageContext.Provider>
    );
};