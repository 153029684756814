import getConfig from 'next/config';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { classNames } from 'primereact/utils';
import React, { forwardRef, useContext, useImperativeHandle, useRef, useState } from 'react';
import { LayoutContext } from './context/layoutcontext';
import useResetState from '../model/redux/utils/useResetState';
import { connect, useStore } from 'react-redux';
import { UsuarioActions, UsuarioSelectors } from '../model/redux/reducers/UsuarioReducer';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { store } from '../model/redux/store';
import { useGlobalMessage } from '../components/ui/global_message';

const AppTopbar = forwardRef((props, ref) => {
    const router = useRouter();
    const { resetState } = useResetState();
    const [sucursalesDialog, setSucursalesDialog] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const dt = useRef(null);

    const { getState } = useStore(store);
    const usuarioLog = UsuarioSelectors.data(getState());
    const { clearMessages } = useGlobalMessage();

    const handleLogout = () => {
        try {
            clearMessages();
            resetState()
            router.push('/auth/login')
        } catch (error) {
            console.error(error)
        }
    };

    const handleChangeSucursal = () => {
        setSucursalesDialog(true)
    };

    const hideSucursalDialog = () => {
        setSucursalesDialog(false);
    };

    const onSelectSucursal = (sucursal) => {
        props.setSucursal(sucursal)
        hideSucursalDialog()
        window.location.reload();
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onChange={(e) => setGlobalFilter(e.target.value)} placeholder="buscar..." />
            </span>
        </div>
    );


    const codeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Código</span>
                {rowData.codigoSucursal}
            </>
        );
    };

    const descripcionTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Descripción</span>
                {rowData.descripcionSucursal}
            </>
        );
    };

    const { layoutState, onMenuToggle, showProfileSidebar } = useContext(LayoutContext);
    const menubuttonRef = useRef(null);
    const topbarmenuRef = useRef(null);
    const topbarmenubuttonRef = useRef(null);
    const contextPath = getConfig().publicRuntimeConfig.contextPath;

    useImperativeHandle(ref, () => ({
        menubutton: menubuttonRef.current,
        topbarmenu: topbarmenuRef.current,
        topbarmenubutton: topbarmenubuttonRef.current
    }));

    return (
        <>
            <div className="layout-topbar">
                <Link href="/" className="layout-topbar-logo">
                    {/* <img src={`${contextPath}/layout/images/logo-${layoutConfig.colorScheme !== 'light' ? 'white' : 'dark'}.svg`} width="47.22px" height={'35px'} widt={'true'} alt="logo" /> */}
                    <img src={usuarioLog?.compania?.logoUrl  != undefined ? usuarioLog?.compania?.logoUrl  : `${contextPath}/layout/images/not-found-local.png`} width="47.22px" height={'35px'} widt={'true'} alt="logo" />
                    <span></span>
                    <span>{  props.sucursalSeleccionada?.descripcionSucursal}</span>
                </Link>

                <button ref={menubuttonRef} type="button" className="p-link layout-menu-button layout-topbar-button" onClick={onMenuToggle}>
                    <i className="pi pi-bars" />
                </button>

                <button ref={topbarmenubuttonRef} type="button" className="p-link layout-topbar-menu-button layout-topbar-button" onClick={showProfileSidebar}>
                    <i className="pi pi-ellipsis-v" />
                </button>

                <div ref={topbarmenuRef} className={classNames('layout-topbar-menu', { 'layout-topbar-menu-mobile-active': layoutState.profileSidebarVisible })}>
                    <button onClick={handleChangeSucursal} type="button" className="p-link layout-topbar-button">
                        <i className="pi pi-building"></i>
                        <span>Cambiar Sucursal</span>
                    </button>
                    <Button tooltip={usuarioLog?.usuario?.usuario} type="button" className="p-link layout-topbar-button">
                        <i className="pi pi-user"></i>
                        <span>Perfil</span>
                    </Button>

                    <button onClick={handleLogout} type="button" className="p-link layout-topbar-button">
                        <i className="pi pi-sign-out"></i>
                        <span>Cerrar Sesión</span>
                    </button>

                </div>
            </div>
            <div>
                <Dialog visible={sucursalesDialog} style={{ width: '850px' }} header="Sucursales Disponible" modal className="p-fluid" onHide={hideSucursalDialog}>
                    <DataTable
                        ref={dt}
                        value={props.sucursalesUsuario}
                        selection={props.sucursalSeleccionada}
                        onSelectionChange={(e) => onSelectSucursal(e.value)}
                        dataKey="codigoSucursal"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[10, 20, 30]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} Sucursales"
                        globalFilter={globalFilter}
                        emptyMessage="No se encontraron Sucursales."
                        header={header}
                        responsiveLayout="scroll"
                    >
                        <Column selectionMode="single" headerStyle={{ width: '4rem' }}></Column>
                        <Column field="codigoSucursal" header="Código" sortable body={codeBodyTemplate} headerStyle={{ minWidth: '10rem' }}></Column>
                        <Column field="descripcionSucursal" header="Descripción" sortable body={descripcionTemplate} headerStyle={{ minWidth: '10rem' }}></Column>
                      
                    </DataTable>
                </Dialog>
            </div>
        </>
    );
});

function mapSateToProps(state) {

    const sucursalSeleccionada = UsuarioSelectors.sucursalSeleccionada(state)
    const sucursalesUsuario = UsuarioSelectors.sucursalesUsuario(state)

    return { sucursalSeleccionada, sucursalesUsuario };
}

function mapDispatchToProps(dispatch) {

    const setSucursal = (sucursal) =>
        dispatch(UsuarioActions.setSucursalSelected(sucursal))

    return { setSucursal };
}


export default connect(mapSateToProps, mapDispatchToProps)(AppTopbar);
