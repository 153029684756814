import { serverConfig } from "../../../conf/serverConfig";
import { isEmpty } from "../../../utils";
import { headersTemplates } from "./headersTemplates";
import { ResponseHandler } from "./responseHandler";

export const client = {

    requestGet: async function(endpoint){

        let header = await headersTemplates.get();
        let response = fetch(`${serverConfig.serverContextPath}/${endpoint}`, header);
        let data = await ResponseHandler.resolve(response)

        return data;
    }, 

    requestGetPagined: async function(endpoint, page, size, filtro){

        endpoint = UrlUtils.addQueryParams({url:endpoint, page, size, filtro});

        let header = await headersTemplates.get();
        let response = fetch(`${serverConfig.serverContextPath}/${endpoint}`, header);
        let data = await ResponseHandler.resolve(response)

        return data;
    },

    requestWithParams: async function(endpoint, params={}){

        endpoint = UrlUtils.addQueryParamsGet(endpoint, params);

        let header = await headersTemplates.get();
        let response = fetch(`${serverConfig.serverContextPath}/${endpoint}`, header);
        let data = await ResponseHandler.resolve(response)

        return data;
    },

    requestPost: async function(endpoint, body){
        
        let header = await headersTemplates.post({body});
        let response = fetch(`${serverConfig.serverContextPath}/${endpoint}`, header);
        let data = await ResponseHandler.resolve(response)

        return data;
    },

    requestPostWithoutToken: async function(endpoint, body){
        
        let header = await headersTemplates.postWithoutToken({body:body});
        let response = fetch(`${serverConfig.serverContextPath}/${endpoint}`, header);
        let data = await ResponseHandler.resolve(response)

        return data;
    },

    requestPostWithToken: async function(endpoint, token, body){
        
        let header = await headersTemplates.post({body:body, token:token});
        let response = fetch(`${serverConfig.serverContextPath}/${endpoint}`, header);
        let data = await ResponseHandler.resolve(response)

        return data;
    },

    requestPostText: async function(endpoint, body){
        
        let header = await headersTemplates.postText({body});
        let response = fetch(`${serverConfig.serverContextPath}/${endpoint}`, header);
        let data = await ResponseHandler.resolve(response)

        return data;
    },
    requestPut: async function(endpoint, body){
        
        let header = await headersTemplates.put({body});
        let response = fetch(`${serverConfig.serverContextPath}/${endpoint}`, header);
        let data = await ResponseHandler.resolve(response);

        return data;
    },
    requestDelete: async function(endpoint, body){

        let header = await headersTemplates.delete({body});
        let response = fetch(`${serverConfig.serverContextPath}/${endpoint}`, header);
        let data = await ResponseHandler.resolve(response)

        return data;
    },

    requestDeleteWithParams: async function(endpoint, params={}){

        endpoint = UrlUtils.addQueryParamsGet(endpoint, params);

        let header = await headersTemplates.delete({body:null});
        let response = fetch(`${serverConfig.serverContextPath}/${endpoint}`, header);
        let data = await ResponseHandler.resolve(response)

        return data;
    },
}

export const UrlUtils = {

    addQueryParams({url, page, size, filtro}){
        
        let queryParams = [];

        if(!isEmpty(page) && !isEmpty(size)){
            queryParams.push(`page=${page}`);
            queryParams.push(`size=${size}`);
        }

        if(!isEmpty(filtro)){
            queryParams.push(`filtro=${filtro}`)
        }

        if(queryParams.length > 0){
            return `${url}?${queryParams.join('&')}`
        }

        return url;

    },

    addQueryParamsGet(url, params={}){
        // params = {"codigoSucursal":4}
        let queryParams = [];

        let keysParam = Object.keys(params);

        if(keysParam.length>0){
            queryParams = keysParam.map((key)=> {
                return isEmpty(params[key])? null : `${key}=${params[key]}`;
            });
        }

        queryParams = queryParams.filter( item => item !== null );

        if(queryParams.length > 0){
            return `${url}?${queryParams.join('&')}`
        }

        return url;

    }
}